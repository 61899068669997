










































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  OfferPayload,
  OrderBumpPayload,
} from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import { OfferType } from '@/types/offers';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';
import GeneralHelper from '@/shared/helpers/general';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';

@Component({
  components: {
    OrderBumpSelectOffer: () => import('./OrderBumpSelectOffer.vue'),
    OrderBumpSelect: () => import('./OrderBumpSelect.vue'),
    OrderBumpPreviewSection: () => import('./OrderBumpPreviewSection.vue'),
    MButton: () => import('@/shared/components/MButton.vue'),
    MConfirmModal: () => import('@/shared/components/MConfirmModal.vue'),
  },
})
export default class OrderBumpEdit extends Vue {
  initialEnabled: boolean = false;
  selectedOrderBumps: Array<OrderBumpPayload> = [];
  availableOffers: Array<OfferPayload> = [];
  selectedOffer: OfferPayload = {
    id: null,
    title: '',
    school_product_id: null,
    kind: OfferType.COMMON,
    enabled: false,
  };

  @Prop({ required: true }) orderBumpId: number;

  async mounted() {
    try {
      const response = await SparkEcommerceApiClient.orderBump.getOrderBump(this.orderBumpId);
      this.selectedOffer = this.buildOffer(response);
      this.initialEnabled = response.enabled;
      this.selectedOrderBumps = this.buildOrderBumpItems(response);

      const { data } = await SparkEcommerceApiClient.offers.all(
        this.$route.params.id,
        {
          exclude_with_order_bump: true,
          offer_id: this.selectedOffer.id,
          kind: 'common',
          items: 999,
        },
      );

      if (data?.payment_options) this.availableOffers = data.payment_options;
    } catch {
      ToastHelper.dangerMessage('Erro ao carregar oferta');
    }
  }

  get hasOfferSelected() {
    return Boolean(this.selectedOffer.id);
  }

  get saveIsEnabled() {
    const hasNegativeAnchorPrice = this.selectedOrderBumps.some(({ anchorPrice }) => anchorPrice <= 0);

    return this.hasOfferSelected && Boolean(this.selectedOrderBumps.length) && !hasNegativeAnchorPrice;
  }

  async editOrderBump() {
    try {
      const { selectedOffer, selectedOrderBumps } = this;

      const formattedOrderBumpList = this.buildOrderBumpListPayload(selectedOrderBumps);

      const payload = {
        offer_id: selectedOffer.id,
        core_product_id: selectedOffer.school_product_id,
        items: formattedOrderBumpList,
        enabled: this.initialEnabled,
      };

      await SparkEcommerceApiClient.orderBump.update(this.orderBumpId, payload);

      TrackingHelper.trackOrderBumpUpdated(
        selectedOffer.school_product_id,
        selectedOffer.id,
        formattedOrderBumpList.length
      );

      this.$emit('back-to-list');
    } catch (error) {
      ToastHelper.dangerMessage('Erro ao editar order bump');
    }
  }

  buildOrderBumpListPayload(orderBumpList) {
    return orderBumpList.map(item => ({
      offer_id: item.id,
      base_price: item.basePrice,
      anchor_price: GeneralHelper.valueInCents(item.anchorPrice),
      call_to_action: item.callToAction,
      description: item.description,
    }));
  }

  selectOffer(offer) {
    this.selectedOffer = offer;
  }

  selectOrderBump(orderBumpList) {
    this.selectedOrderBumps = orderBumpList;
  }

  buildOffer(offer) {
    return {
      id: offer.offer_id,
      title: offer.title,
      school_product_id: offer.core_product_id,
      kind: offer.offer_kind,
      enabled: offer.enabled,
      price: offer.price,
    };
  }

  buildOrderBumpItems(offer) {
    const { items } = offer;

    return items.map(item => {
      return {
        id: item.offer_id,
        title: item.product_name,
        coverImageUrl: item.cover_url,
        anchorPrice: item.anchor_price / 100,
        basePrice: item.base_price,
        callToAction: item.call_to_action || '',
        description: item.description || '',
      };
    });
  }

  cancelOrderBump() {
    this.$bvModal.hide('order-bump-cancel-edit-modal');
    this.$emit('back-to-list');
  }
}
